import React, { useContext, useEffect, useRef, useState } from "react";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";
import Clients from "../components/common/Clients";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { StaticImage } from "gatsby-plugin-image";
import { Fragment } from "react";
import { Link } from "gatsby";
import TextBox from "../components/inputs/Textbox";

const Articles = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  let container1 = useRef(null);

  const [txtValues, setTxtValues] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTxtValues({ ...txtValues, [name]: value });
  };

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      const cn1 = container1.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
        cn1.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Latest Articles | VIRSAT'
        description='Find out the latest articles of VIRSAT - Virtual Reality Safety Training'
      />
      {!state.showPageLoading && (
      <Fragment>
        <PageBanner title='Articles' subtitle='Latest VIRSAT Articles' />
          <section
            className='main-container articles hide-container'
            ref={container}
          >
            <div className='row top-pad bottom-pad fd-col'>
              <article className='column article-lists'>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/articles/virsat-adipec-2024/">
                    <StaticImage
                      src='../assets/img/pages/newsletter/adipec-2024/virsat-adipec-2024.jpg'
                      alt='Virtual Reality Driving Simulator'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>VIRSAT Showcases Innovative Virtual Reality Safety Training Solutions at ADIPEC 2024</h3>
                      <p>Virtual Reality Safety Training (VIRSAT) is proud to participate in ADIPEC for the third consecutive year, where they showcased their state-of-art Virtual Reality innovative products.</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="https://onepetro.org/SPEHSE/proceedings-abstract/24HSE/24HSE/D011S007R006/555465">
                    <StaticImage
                      src='../assets/img/pages/newsletter/six-degress-freedom-simulator-dessert-driving.jpg'
                      alt='Development of a Six Degrees of Freedom Simulator for Desert Driving Training'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>Development of a Six Degrees of Freedom Simulator for Desert Driving Training</h3>
                      <p>This paper describes a recently developed desert driving simulator. It is new for the oil and gas industry and signifies a major step forward in the ongoing journey to zero road accidents.</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="https://onepetro.org/SPEHSE/proceedings-abstract/24HSE/24HSE/D011S007R004/555531">
                    <StaticImage
                      src='../assets/img/pages/articles/transforming-driver-safety-training.jpg'
                      alt='Development of a Six Degrees of Freedom Simulator for Desert Driving Training'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>Transforming Driver Safety Training in the Oil and Gas Industry Through Virtual Reality</h3>
                      <p>Global warming is on top of many people's mind as it potentially impacts the whole world population. However, an actual big impact on people are workplace related incidents.</p>
                    </div>
                  </Link>
                </article>
                
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/news/virtual-reality-driving-simulator/">
                    <StaticImage
                      src='../assets/img/pages/newsletter/simulator/Main-img.jpg'
                      alt='Virtual Reality Driving Simulator'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>Virtual Reality Driving Simulator</h3>
                      <p>A century ago, the Wright Brothers developed the first plane. It was not very safe at first. Throughout the world clever engineers developed solutions for making planes safer.</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/news/virsat-brings-new-virtual-reality-training-adipec-2022/">
                    <StaticImage
                      src='../assets/img/pages/articles/VIRSAT@ADIPEC.jpg'
                      alt='VIRSAT Brings New Virtual Reality Training in ADIPEC'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>VIRSAT Brings New Virtual Reality Training in ADIPEC</h3>
                      <p>Virtual Reality Safety Training (VIRSAT) is pleased to announce its participation in ADIPEC to launch a set of its new Virtual Reality innovations and products.</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/articles/impress-visitors-sophisticated-safety-induction-life-saving-rules/">
                    <StaticImage
                      src='../assets/img/pages/articles/impress-visitors-sophisticated-safety-induction-life-saving-rules.jpg'
                      alt='How to Impress Your Visitors with a Sophisticated Safety Induction on the Life-Saving Rules?'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>How to Impress Your Visitors with a Sophisticated Safety Induction on the Life-Saving Rules</h3>
                      <p>IOGP introduced 9 Life-Saving Rules. Rules which save lives and deal with the most hazardous activities in the Oil and Gas industry....</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/articles/vr-training-reduce-fatal-road-incidents-middle-east/">
                    <StaticImage
                      src='../assets/img/pages/articles/vr-training-reduce-fatal-road-incidents-middle-east.jpg'
                      alt='Will newly-developed VR training for drivers help reduce fatal road incidents in the Middle East?'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>Will newly-developed VR training for drivers help reduce fatal road incidents in the Middle East?</h3>
                      <p>Fatal road safety incidents in a number of Middle East countries like Oman, UAE, and KSA are a major cause of fatalities...</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/articles/innovative-way-train-workers-fatal-hse-risk/">
                    <StaticImage
                      src='../assets/img/pages/articles/4/New and innovative way to train contractor workers on fatal HSE risks.jpg'
                      alt='New and Innovative Way to Train Contractor Workers on Fatal HSE Risks.'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>New and Innovative Way to Train Contractor Workers on Fatal HSE Risks.</h3>
                      <p>The contractor workers run much higher risk of a fatal incident than company workers. This is clear by the safety...</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/articles/vr-games-new-way-make-work-significantly-safer/">
                    <StaticImage
                      src='../assets/img/pages/vr-games/virsat-virtual-games.jpg'
                      alt='VR Games: A New Way to Make Work Significantly Safer'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>VR Games: A New Way to Make Work Significantly Safer</h3>
                      <p>Virtual Reality games are an innovative way to train workers in the field. These games are low cost and fun to play.</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/articles/vr-games-new-way-create-significant-safety-impact/">
                    <StaticImage
                      src='../assets/img/pages/articles/VR Games A New Way to Create Significant Safety Impact.jpg'
                      alt='VR Games: A New Way to Make Work Significantly Safer'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>VR Games: A New Way to Create Significant Safety Impact</h3>
                      <p>In this article, the features of VR are explained in the context of adult learning and how it can be used to create improved...</p>
                    </div>
                  </Link>
                </article>
                <article className='article-thumb no-mar-bottom' data-aos='fade-in'>
                  <Link to="/articles/5-reasons-why-companies-should-consider-vr-learning-tool/">
                    <StaticImage
                      src='../assets/img/pages/articles/5 Reasons Why Companies Should Consider.jpg'
                      alt='VR Games: A New Way to Make Work Significantly Safer'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>5 Reasons Why Companies Should Consider VR as a Learning Tool</h3>
                      <p>According to PWC, “Employers are facing a dilemma: Their workforce needs to learn new skills, upgrade...</p>
                    </div>
                  </Link>
                </article>
              </article>
            </div>
          </section>
          <section className='main-container common-newsletter hide-container' ref={container1}>
            <div className="row fj-center fa-center bottom-mar" data-aos='fade-in'>
              <div className='column image'>
                <StaticImage
                  src='../assets/img/pages/articles/newsletter-subscription-image.png'
                  alt='Subscript to Newsletter'
                  placeholder='blurred'
                  objectFit
                  className='gatsby-img'
                />
              </div>
              <div className="column details">
                <h3 className="main-title small light no-mar">Subscribe to Newsletter</h3>
                <p className='no-mar light remove-br-mobile'>
                  Discover the latest innovation, new products released, <br/>offers and promotions, and upcoming events.
                </p>
                <div className='text-link flex fa-center'>
                  <TextBox
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Eq. john@yourcompany.com'
                    txtBoxClass='regular-text'
                    onChange={handleInputChange}
                    value={txtValues.email}
                    required
                    divStyle={{width:'50%'}}
                    divClass='no-margin'
                  />
                  <Link className='button-link' to='/lp/newsletter' state={{'email': txtValues.email }}>Subscribe</Link>
                </div>
              </div>
            </div>
          </section>
          <Clients />
        </Fragment>
      )}
    </Layout>
  );
};

export default Articles;